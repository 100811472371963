<template>
  <div class="product">
    <!-- 菜单 -->
    <my-nav id="myNav" pageName="Product"></my-nav>
    <div class="banner-box">
      <div class="banner-img"></div>
    </div>
    <div v-for="item in productList" :key="item.title" class="product-item">
      <div :class="['wrap', item.imgPosition]" :style="'background-image: url(' + item.img + ');'">
        <div class="product-text">
          <div class="product-center">
            <div class="product-title">{{ item.title }}</div>
            <div class="product-article">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <my-footer id="footer"></my-footer>
  </div>
</template>

<script>
import MyNav from "../../components/nav/nav";
import MyFooter from "../../components/footer/footer";
export default {
  name: "Product",
  components: {
    MyNav,
    MyFooter,
  },
  data() {
    return {
      productList: [
        {
          img: require('../../assets/images/productImg01.png'),
          title: 'SUSPENSION SERIES',
          text: 'Shock absorber, coil spring, front suspension strut, left and right swing arms and transverse stabilizer bar',
          imgPosition: 'right'
        },
        {
          img: require('../../assets/images/productImg02.png'),
          title: 'THE BRAKE SYSTEM',
          text: 'Brake System Products include:brake discs, brake pads, brake sensors, brake calipers, handbrake modules, handbrake switch, brake hoses,etc.',
          imgPosition: 'left'
        },
        {
          img: require('../../assets/images/productImg03.png'),
          title: 'STEERING SYSTEM',
          text: 'Steering system includes steering wheel, steering shaft, steering intermediate shaft, steering oil pipe, steering oil pump, steering oil tank, steering knuckle arm, - steering tie rod, steering rocker arm, integral steering, steering tie rod, steering shock absorber and other components.',
          imgPosition: 'right'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.product {
  .banner-box {
    background-image: linear-gradient(-90deg, #3E4C5C, #1C1F22);
    .banner-img {
      width: 100%;
      height: 500px;
      background: url("../../assets/images/bannerProduct.png") no-repeat center;
      background-size: auto 500px;
    }
  }
  .product-item {
    width: 100%;
    height: auto;
    background: #1A1A1A;
    &:nth-of-type(2n) {
      background: #1F1F1F;
    }
    .wrap {
      width: 1458px;
      height: 476px;
      margin: 0 auto;
      background-size: auto 473px;
      background-repeat: no-repeat;
      box-sizing: border-box;
      .product-text {
        width: 500px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        .product-center {
          width: 466px;
          height: auto;
          color: #fff;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          .product-title {
            width: 100%;
            line-height: 28px;
            font-size: 28px;
            font-weight: bold;
            padding-bottom: 32px;
          }
          .product-article {
            width: 100%;
            line-height: 24px;
            font-size: 18px;
            text-align: justify;
          }
        }
      }
      &.left {
        background-position: center left;
        .product-text {
          margin-left: 700px;
        }
      }
      &.right {
        background-position: center right;
        .product-text {
          margin-right: 700px;
        }
      }
    }
  }
}
</style>